import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import './Header.scss'

const scaleVariants = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}


const Header = () => {
    return (
        <div className='app__header app__flex'>
            <motion.div
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 1.5 }}
                className='app__header-info'
            >
                <div className='app__header-badge'>
                <div className='badge-cmp app__flex'>
                       <span>⚡️🔋⚡️ </span>
                        <div style={{ marginLeft: 20 }}>
                            <a href='https://www.store-autismlevelup.com/product-page/energy-the-framework-tools-strategies-logic-to-support-regulation' target='_blank' rel='noreferrer'>
                            <p className='p-text'>The Energy Manual</p>
                            <h1 className='head-text'>is here.</h1>
                            </a>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className='badge-cmp app__flex'>
                        <span>👋 </span>
                        <div style={{ marginLeft: 20 }}>
                            <p className='p-text'>Hello there, we're</p>
                            <h1 className='head-text'>Amy & Jac</h1>
                        </div>
                    </div>
                    <div className='tag-cmp app__flex'>
                        <p className='p-text'>An Autistic-Allistic partnership</p>
                        <p className='p-text'>leveling UP! society</p>
                    </div>
                    
                    
                </div>
            </motion.div>


            <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className='app__header-img'
            >
                <img src={images.logo} alt='autism level up logo' />

                <motion.img
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    className='overlay_circle'
                    src={images.circle}
                    alt='logo circle'
                />
            </motion.div>

            <motion.div
                variant={scaleVariants}
                whileInView={scaleVariants.whileInView}
                className='app__header-circles'
            >
                {[images.fun, 
                    images.pow, images.stars].map((circle, index) => (
                    <div className='circle-cmp app__flex' key={`cirlce-${index}`}>
                        <img src={circle} alt='circlular graphic' />
                    </div>
                ))}
            </motion.div>
           
        </div>
    );
}

export default AppWrap(Header, 'home');