import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';
import Moment from 'react-moment';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Events.scss';


const Events = () => {

    const [events, setEvents] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const query = '*[_type == "events"]';
        const prodQuery = '*[_type == "products"]';

        client.fetch(query).then((data) => {
            setEvents(data);
        });

        client.fetch(prodQuery).then((data) => {
            setProducts(data);
        });
    }, []);

    return (

        <>
            <br />
            <h2 className="head-text">For Pay: Products & Upcoming Events</h2>

            <div className="app__skills-container">
                <motion.div className="app__skills-exp">
                    {events.map((event) => (
                        <motion.div
                            whileInView={{ opacity: [0, 1] }}
                            transition={{ duration: 0.5 }}
                            className="app__skills-item app__flex"
                            key={event.title}
                        >
                            <div
                                className="app__flex"
                                style={{ backgroundColor: event.bgColor }}
                            >
                                <a href={event.eventLink} target="_blank" rel="noreferrer">
                                    <img src={urlFor(event.imgUrl)} alt={event.title} />
                                </a>
                            </div>
                            <p className="p-text" >{event.title}</p>
                            <p className="p-text" >
                                <Moment
                                    parse="YYYY-MM-DDTHH:mm:ss.000z"
                                    format="MMMM Do YYYY"
                                >
                                    {event.date}
                                </Moment><br />
                                {event.time}

                            </p>
                        </motion.div>
                    ))}
                </motion.div>
                <div className="app__skills-exp">
                    {products.map((product) => (
                        <>
                            <motion.div
                                whileInView={{ opacity: [0, 1] }}
                                transition={{ duration: 0.5 }}
                                className="app__skills-item app__flex"
                                key={product.name}
                                data-tip
                                data-for={product.name}
                            >
                                <div
                                    className="app__flex"
                                    style={{ backgroundColor: product.bgColor }}
                                >
                                    <a href={product.exLink} target="_blank" rel="noreferrer">
                                        <img src={urlFor(product.imgUrl)} alt={product.name} className="prod-img" />
                                    </a>
                                </div>
                                <p className="p-text">{product.name}</p>
                                <p className="p-text">{`$${product.price} (USD)`}</p>
                            </motion.div>
                            <ReactTooltip
                                id={product.name}
                                effect="solid"
                                arrowColor="#fff"
                                className="skills-tooltip"
                            >
                                {product.description}
                            </ReactTooltip>
                        </>
                    ))}
                </div>
            </div>
        </>
    )
}

export default AppWrap(
    MotionWrap(Events, 'app__skills'),
    'events & products',
    'app__whitebg'

);