import React, { useState, useEffect } from 'react';
import { AiFillFilePdf, AiFillPlusCircle } from 'react-icons/ai';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Tools.scss';
import { HiXCircle } from 'react-icons/hi';
import { isMobile } from 'react-device-detect';


const Tools = () => {

    const [activeFilter, setActiveFilter] = useState('Essentials');
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
    const [tools, setTools] = useState([]);
    const [filterTools, setFilterTools] = useState([]);

    const [popUpContent, setPopUpContent] = useState([]);
    const [popUpToggle, setPopUpToggle] = useState(false);

    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        const query = '*[_type == "toolposts"]';

        client.fetch(query).then((data) => {

            setTools(data);
            setFilterTools(data.filter((tool) => tool.tags.includes('Essentials')));



        });
    }, []);

    const handleWorkFilter = (item) => {
        setActiveFilter(item);
        setAnimateCard([{ y: 100, opacity: 0 }]);

        setTimeout(() => {
            setAnimateCard([{ y: 0, opacity: 1 }]);

            if (item === 'All') {
                setFilterTools(tools);
            } else {
                setFilterTools(tools.filter((tool) => tool.tags.includes(item)));
            }
        }, 500);
    };

    const handleModal = (tool) => {

        setPopUpContent([
            tool
        ]);

        setPopUpToggle(true);

        document.body.style.overflow = "hidden";

    };

    const closeModal = () => {

        setPopUpToggle(false);
        setPopUpContent([]);
        document.body.style.overflow = "auto";
    }

    return (
        <>
            <br />
            <div className='style-container'>
                <h2 className='head-text'>Our Tools & <span>Supports </span></h2>
                <div className='app__search-container app__flex'>
                    <input className="app__searchbar" placeholder='🔍 Search Tools' onChange={event => setSearchString(event.target.value)} />

                </div>

                <div className='app__work-filter'>
                    {['Essentials', 'Meters', 'Energy', 'Communication', 'Predictability', 'Regulation', 'Advocacy', 'Play', 'Español', 'Goals', 'All'].map((item, index) => (
                        <div
                            key={index}
                            onClick={() => handleWorkFilter(item)}
                            className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
                        >
                            {item}
                        </div>
                    ))}
                </div>

                <motion.div
                    animate={animateCard}
                    transition={{ duration: 0.5, delayChildren: 0.5 }}
                    className='app__work-portfolio'
                >
                    {filterTools.filter(tool => {
                        if (searchString === '') {
                            return tool;
                        } else if (tool.title.toLowerCase().includes(searchString.toLowerCase()) || tool.content.toLowerCase().includes(searchString.toLowerCase())) {
                            return tool;
                        }

                    }).map((tool, index) => (


                        <div className='app__work-item app__flex' key={index}>
                            <div className='app__work-img app__flex'>
                                <a href={tool.pdfLink} target="_blank" rel="noreferrer">
                                    <img src={urlFor(tool.imgUrl)} alt={tool.title} />
                                </a>


                                {!isMobile && <motion.div
                                    whileHover={{ opacity: [0, 1] }}
                                    transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                                    className='app__work-hover app__flex'
                                >
                                    <a href={tool.pdfLink} target="_blank" rel="noreferrer">
                                        <motion.div
                                            whileInView={{ scale: [0, 1] }}
                                            whileHover={{ scale: [1, 0.9] }}
                                            transition={{ duration: 0.25 }}
                                            className='app__flex'

                                        >
                                            <AiFillFilePdf />
                                        </motion.div>
                                    </a>

                                    <motion.div
                                        whileInView={{ scale: [0, 1] }}
                                        whileHover={{ scale: [1, 0.9] }}
                                        transition={{ duration: 0.25 }}
                                        className='app__flex'


                                    >
                                        {!popUpToggle && <AiFillPlusCircle
                                            onClick={() => handleModal(tool)}
                                        />}




                                    </motion.div>

                                </motion.div>}


                            </div>

                            <div className='app__work-content app__flex'>
                                <h4 className='bold-text'>{tool.title}</h4>
                                <p className='p-text' style={{ marginTop: 10 }}> {`${tool.content.substring(0, 106)}...`}</p>

                                <div className='app__work-tag app__flex'>
                                    <p className='p-text'>{tool.tags[0]}</p>
                                </div>
                            </div>

                        </div>



                    )
                    )
                    }

                </motion.div>
            </div>






            {popUpToggle && (
                <div className='popup_container' onClick={closeModal}
                >
                    <div className='popup_body' onClick={(e) => e.stopPropagation()}>
                        <div className="popup_header" onClick={closeModal}>
                            <HiXCircle size={40} />
                        </div>

                        <div className='popup_content'>

                            {popUpContent.map((pop) => {
                                return (
                                    <div className="popup_card">
                                        <div className='image_container app__flex'>
                                            <a href={`${pop.pdfLink}`} target="_blank" rel="noreferrer">
                                                <img src={urlFor(pop.imgUrl)} alt={pop.title} />
                                            </a>
                                        </div>


                                        <h2 className="head-text">{`About ${pop.title}:`}</h2>

                                        <ReactMarkdown className="p-text">{pop.contentF}</ReactMarkdown>




                                    </div>

                                )

                            })}
                        </div>
                    </div>



                </div>)}


        </>

    )
}


export default AppWrap(
    MotionWrap(Tools, 'app__works'),
    'tools',
    'app__primarybg'
);